import { getPortfolio } from '@/api/getPortfolio'
import LoadingOverlay from '@/components/loadingOverlay'
import PortfolioHeader from '@/components/portfolioHeader'
import PortfolioSiderbar from '@/components/portfolioSidebar'
import { SidebarProvider } from '@/components/ui/sidebar'
import {
  AnalyzeProgressToast,
  UpdatingTransactionToast,
  updateIsRequiredCalculationAtom,
} from '@/features/analyze'
import { setupPortfolio } from '@/features/portfolio/api/setupPortfolio'
import { useAuth } from '@clerk/clerk-react'
import { useQuery } from '@tanstack/react-query'
import {
  Outlet,
  createFileRoute,
  useNavigate,
  useRouterState,
} from '@tanstack/react-router'
import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const Route = createFileRoute('/_layoutPortfolio')({
  component: LayoutComponent,
})

const notPortfolioPathnames = [
  '/forbid-multitab',
  '/onboarding',
  '/signIn',
  '/signIn/factor-one',
  'waitlist',
]

function LayoutComponent() {
  const { getToken } = useAuth()
  const { location } = useRouterState()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [, updateIsRequiredCalculation] = useAtom(
    updateIsRequiredCalculationAtom,
  )

  const { data, isLoading } = useQuery({
    queryKey: ['setup-portfolio', location.search.id],
    queryFn: async () => {
      if (notPortfolioPathnames.includes(location.pathname)) {
        return null
      }
      const token = await getToken()
      if (token === null) {
        return null
      }
      const result = await setupPortfolio({
        token,
        idByQuery: location.search.id,
      })
      if (location.search.id !== result.portfolio.uuid) {
        await navigate({
          to: location.pathname,
          search: () => ({
            id: result.portfolio.uuid,
          }),
        })
      } else {
        const portfolio = await getPortfolio({
          readFromDatabase: result.portfolio.readFromDatabase,
          writeToDatabase: result.portfolio.writeToDatabase,
        })
        if (portfolio.startTime === null) {
          await navigate({
            to: '/onboarding',
            search: (prev) => ({
              ...prev,
            }),
          })
        }
      }
      return result
    },
    retry: false,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (data === undefined || data === null) {
      return
    }
    updateIsRequiredCalculation({
      readFromDatabase: data.portfolio.readFromDatabase,
    })
  }, [data, updateIsRequiredCalculation])

  if (isLoading || data === undefined || data === null) {
    return <LoadingOverlay description={t('loading/setup-portfolio')} />
  }

  const portfolio = data.portfolios.find((p) => p.uuid === data.portfolio.uuid)
  if (portfolio === undefined) {
    return <LoadingOverlay description={t('loading/portfolio-not-found')} />
  }

  return (
    <SidebarProvider>
      <PortfolioSiderbar
        portfolios={data.portfolios}
        portfolio={portfolio}
        readonly={data.portfolio.readonly}
      />
      <div className="relative flex h-screen flex-1 flex-col overflow-x-hidden">
        <PortfolioHeader />
        <div className="relative w-full flex-1 overflow-y-hidden">
          <Outlet />
        </div>
        <UpdatingTransactionToast {...data.portfolio} />
        {/* <AnalyzeProgressToastWrapper {...data.portfolio} /> */}
      </div>
      <AnalyzeProgressToast />
    </SidebarProvider>
  )
}
