import type { toast } from '@/hooks/useToast'
import {
  AuthTokenExpiredException,
  FailedToAnalyzeEvmChainException,
  FailedToAnalyzeEvmTxException,
  FailedToAnalyzeExchangeApiException,
  FailedToAnalyzeExchangeException,
  FailedToAnalyzePolkadotChainException,
  FailedToAnalyzeSolanaException,
  FailedToAnalyzeSolanaTxException,
  FailedToFetchEvmIndexDataException,
  FailedToFetchEvmTxException,
  FailedToFetchPolkadotBlockDataException,
  FailedToFetchPolkadotIndexDataException,
  FailedToFetchSolanaAddressException,
  FailedToFetchSolanaTxException,
  UnwritableDatabaseException,
} from '@pkg/basic'
import { captureException } from '@sentry/react'
import type { TFunction } from 'i18next'

export const showErrorToast = (params: {
  error: unknown
  t: TFunction
  toast: typeof toast
}) => {
  const { error, t, toast } = params
  let stopPropagation = false
  if (
    error instanceof Error &&
    error.message.includes('Failed to fetch dynamically imported module')
  ) {
    stopPropagation = true
    window.location.reload()
  } else if (error instanceof AuthTokenExpiredException) {
    toast({
      variant: 'destructive',
      description: t('error/auth-token-expired'),
    })
    stopPropagation = true
  } else if (error instanceof UnwritableDatabaseException) {
    toast({
      variant: 'destructive',
      description: t('error/unwritable-database'),
    })
    stopPropagation = true
  } else if (error instanceof FailedToAnalyzeExchangeException) {
    toast({
      variant: 'destructive',
      description: t('error/failed-to-analyze-exchange', {
        exchange: error.exchangeName,
      }),
    })
  } else if (error instanceof FailedToAnalyzeExchangeApiException) {
    const api = parseToExchangeApiName({
      exchange: error.exchangeName,
      apiId: error.apiId,
      t,
    })
    toast({
      variant: 'destructive',
      description: t('error/failed-to-analyze-exchange-api', {
        exchange: error.exchangeName,
        api,
      }),
    })
  } else if (error instanceof FailedToAnalyzeEvmChainException) {
    toast({
      variant: 'destructive',
      description: t('error/failed-to-analyze-evm-chain', {
        chain: error.chainName,
      }),
    })
  } else if (error instanceof FailedToFetchEvmIndexDataException) {
    toast({
      variant: 'destructive',
      description: t('error/failed-to-fetch-evm-index-data', {
        chain: error.chainName,
        address: error.address,
      }),
    })
  } else if (error instanceof FailedToFetchEvmTxException) {
    toast({
      variant: 'destructive',
      description: t('error/failed-to-fetch-evm-tx', {
        chain: error.chainName,
        hash: error.hash,
      }),
    })
  } else if (error instanceof FailedToAnalyzeEvmTxException) {
    toast({
      variant: 'destructive',
      description: t('error/failed-to-analyze-evm-tx', {
        chain: error.chainName,
        hash: error.hash,
      }),
    })
  } else if (error instanceof FailedToAnalyzePolkadotChainException) {
    toast({
      variant: 'destructive',
      description: t('error/failed-to-analyze-polkadot-chain', {
        chain: error.chainName,
      }),
    })
  } else if (error instanceof FailedToFetchPolkadotIndexDataException) {
    toast({
      variant: 'destructive',
      description: t('error/failed-to-fetch-polkadot-index-data', {
        chain: error.chainName,
        address: error.address,
      }),
    })
  } else if (error instanceof FailedToFetchPolkadotBlockDataException) {
    toast({
      variant: 'destructive',
      description: t('error/failed-to-fetch-polkadot-block-data', {
        chain: error.chainName,
        block: error.blockNumber,
      }),
    })
  } else if (error instanceof FailedToAnalyzeSolanaException) {
    toast({
      variant: 'destructive',
      description: t('error/failed-to-analyze-solana'),
    })
  } else if (error instanceof FailedToFetchSolanaTxException) {
    toast({
      variant: 'destructive',
      description: t('error/failed-to-fetch-solana-tx', {
        signature: error.signature,
      }),
    })
  } else if (error instanceof FailedToFetchSolanaAddressException) {
    toast({
      variant: 'destructive',
      description: t('error/failed-to-fetch-solana-address', {
        address: error.address,
      }),
    })
  } else if (error instanceof FailedToAnalyzeSolanaTxException) {
    toast({
      variant: 'destructive',
      description: t('error/failed-to-analyze-solana-tx', {
        signature: error.signature,
      }),
    })
  } else if (error instanceof Error) {
    toast({
      variant: 'destructive',
      description: t('error/unknown'),
    })
  }
  if (!stopPropagation) {
    captureException(error)
    console.error(error)
  }
}

const parseToExchangeApiName = (params: {
  exchange: string
  apiId: string
  t: TFunction
}): string => {
  const { exchange, apiId, t } = params
  switch (exchange) {
    case 'bitbank': {
      switch (apiId) {
        case 'deposit': {
          return t('exchange-api/bitbank/deposit')
        }
        case 'trade': {
          return t('exchange-api/bitbank/trade')
        }
        case 'withdrawal': {
          return t('exchange-api/bitbank/withdrawal')
        }
      }
      break
    }
    case 'bitget': {
      switch (apiId) {
        case 'deposit': {
          return t('exchange-api/bitget/deposit')
        }
        case 'future-tax': {
          return t('exchange-api/bitget/future-tax')
        }
        case 'spot-tax': {
          return t('exchange-api/bitget/spot-tax')
        }
        case 'withdrawal': {
          return t('exchange-api/bitget/withdrawal')
        }
      }
      break
    }
    case 'bybit': {
      switch (apiId) {
        case 'deposit': {
          return t('exchange-api/bybit/deposit')
        }
        case 'linear-closed-pl': {
          return t('exchange-api/bybit/linear-closed-pl')
        }
        case 'spot-trade': {
          return t('exchange-api/bybit/spot-trade')
        }
        case 'withdrawal': {
          return t('exchange-api/bybit/withdrawal')
        }
      }
      break
    }
    case 'gate': {
      switch (apiId) {
        case 'deposit': {
          return t('exchange-api/gate/deposit')
        }
        case 'withdrawal': {
          return t('exchange-api/gate/withdrawal')
        }
        case 'spot-trade': {
          return t('exchange-api/gate/spot-trade')
        }
      }
      break
    }
    case 'kucoin': {
      switch (apiId) {
        case 'deposit': {
          return t('exchange-api/kucoin/deposit')
        }
        case 'withdrawal': {
          return t('exchange-api/kucoin/withdrawal')
        }
        case 'spot-trade': {
          return t('exchange-api/kucoin/spot-trade')
        }
      }
    }
  }
  return t(`exchange-api/${exchange}/${apiId}`)
}
